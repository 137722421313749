<template>
  <div class="bazaar">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/Frame9617.png"
      alt=""
      class="bazaar-back"
      @click="back()"
    />
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/1413a69cacb7ba1c1bc1348e58696642.png"
      alt=""
      class="bazaar-but"
    />
    <div class="bazaar-content">
      <div class="bazaar-content-type flex">
        <div
          v-for="(item, index) in typeList"
          :key="index"
          :class="
            typeIndex == index
              ? 'bazaar-content-type-li'
              : 'bazaar-content-type-li1'
          "
          @click="typeButton(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="bazaar-content-exhibition">
        <div class="bazaar-content-exhibition-click flex">
          <div
            class="bazaar-content-exhibition-click-left"
            @click="typeButton(0)"
          ></div>
          <div
            class="bazaar-content-exhibition-click-left"
            @click="typeButton(1)"
          ></div>
        </div>
        <div class="flex">
          <div>
            <div class="bazaar-content-exhibition-introduce-right-title">
              {{ commodityDetails.productTypeName }}
            </div>
            <div class="bazaar-content-exhibition-introduce-left">
              <img
                :src="commodityDetails.productImg"
                alt=""
                class="bazaar-content-exhibition-introduce-left-img"
              />
            </div>
          </div>
          <div class="bazaar-content-exhibition-introduce flex">
            <div class="bazaar-content-exhibition-introduce-right">
              <div class="bazaar-content-exhibition-introduce-right-text">
                {{ typeIndex == 0 ? "当前寄售最低" : "当前求购最高" }}价格:<span
                  class="bazaar-content-exhibition-introduce-right-pic"
                  v-if="nowprice"
                  >{{ nowprice }}</span
                >
                <span
                  class="bazaar-content-exhibition-introduce-right-pic"
                  v-else
                  >暂无</span
                >
              </div>
              <div class="bazaar-content-exhibition-introduce-right-t">
                <!-- <div class="">商品描述：</div> -->
                <div class="bazaar-content-exhibition-introduce-right-t-w">
                  {{ commodityDetails.productDescribe }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bazaar-content-exhibition-list">
          <div class="bazaar-content-exhibition-list-type flex">
            <div
              class="bazaar-content-exhibition-list-type-left flex"
              @click="expansionSort()"
            >
              <div>价格</div>
              <img
                src="https://cdn.bubbleplan.cn/static/marketplace/Vector693.png"
                alt=""
                class="bazaar-content-exhibition-list-type-left-img"
                :class="expansionSortShow ? 'overturn' : ''"
              />
            </div>
            <div
              class="bazaar-content-exhibition-list-type-show"
              v-show="expansionSortShow"
            >
              <div
                v-for="(item, index) in priceRankingList"
                :key="index"
                :class="
                  sort == index ? 'capreolusPygargus' : 'capreolusPygargus'
                "
                @click="sortButton(index)"
              >
                {{ item.name }}
              </div>
            </div>

            <div class="bazaar-content-exhibition-list-type-right">数量</div>
          </div>
          <div class="bazaar-content-exhibition-list-ul" @scroll="handleScroll">
            <div
              v-for="(item, index) in marketList"
              :key="index"
              class="bazaar-content-exhibition-list-ul-li flex"
            >
              <div style="width: 15%; text-align: center">
                <img
                  :src="commodityDetails.productImg"
                  alt=""
                  class="bazaar-content-exhibition-list-ul-li-img"
                />
              </div>
              <div style="width: 24%">价格：{{ item.price }}</div>
              <div style="width: 28%">
                数量：{{ (item.totalCount - item.purchasedCount).toFixed(0) }}
              </div>
              <div style="width: 33%; justify-content: center" class="flex">
                <div
                  class="bazaar-content-exhibition-list-ul-li-but"
                  @click="listButton(item)"
                >
                  {{ typeIndex == 0 ? "购买" : "出售" }}
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              typeIndex == 0
                ? 'bazaar-content-exhibition-list-button'
                : 'bazaar-content-exhibition-list-button'
            "
            @click="release()"
          >
            {{ typeIndex == 0 ? "发布寄售" : "发布求购" }}
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 74px"></div>
      <el-dialog
        title=""
        :visible.sync="consignmentSalesShow"
        width="30%"
        :show-close="false"
        @close="handleClose()"
      >
        <div class="consignmentSales">
          <!-- <div class="consignmentSales-title">寄售</div> -->
          <img
            src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
            alt=""
            class="consignmentSales-img"
            @click="consignmentSalesShow = false"
          />
          <div class="consignmentSales-commodity flex">
            <img
              :src="commodityDetails.productImg"
              alt=""
              class="consignmentSales-commodity-img"
            />
            <div class="flex flex-direction flex-between">
              <div class="consignmentSales-commodity-title">
                {{ commodityDetails.productTypeName }}
              </div>
              <div class="consignmentSales-commodity-num">
                此商品当前最高出价<span
                  style="font-weight: 700"
                  v-if="topprice"
                  >{{ topprice }}</span
                >
                <span
                  v-else
                  class="consignmentSales-commodity-num"
                  style="color: #e029d9"
                >
                  暂无
                </span>
              </div>
            </div>
          </div>
          <div class="consignmentSales-retail">
            <div class="consignmentSales-retail-title">零售价格</div>
            <div class="consignmentSales-retail-input">
              <el-input v-model="price" placeholder="请输入价格"></el-input>
            </div>
            <div
              class="consignmentSales-retail-serviceCharge flex flex-between"
            >
              <div>平台手续费（{{ (serviceCharge * 100).toFixed(2) }}%）</div>
              <div>{{ serviceChargeNuM }}</div>
            </div>
            <div
              class="consignmentSales-retail-serviceCharge flex flex-between"
            >
              <div>预计收益</div>
              <div style="">{{ anticipatedIncome }}</div>
            </div>
          </div>
          <div class="consignmentSales-retail">
            <div class="consignmentSales-retail-title">上架数量</div>
            <div class="consignmentSales-retail-input">
              <el-input
                v-model="counts"
                placeholder="请输入上架数量"
              ></el-input>
            </div>
            <div
              class="consignmentSales-retail-serviceCharge flex flex-between"
            >
              <div>
                当前还剩<span style="color: #e029d9">{{ remainingNum }}</span
                >个
              </div>
            </div>
          </div>
          <div class="consignmentSales-button" @click="releaseonconsignment()">
            发布寄售
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="passwordShow"
        width="30%"
        :show-close="false"
      >
        <div class="securityCodeSales">
          <img
            src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
            alt=""
            class="securityCodeSales-img"
            @click="passwordShow = false"
          />
          <div class="securityCodeSales-title">安全密码</div>
          <xskcodeinput
            :value.sync="payPassword"
            :width="52"
            :height="59"
            backgroundColor="#FAFAFA"
            bold
            :length="6"
            ref="xskcodeinputRef"
          >
          </xskcodeinput>
          <div class="securityCodeSales-button flex flex-around">
            <div
              class="securityCodeSales-button-left"
              @click="securityCodeSalesClose"
            ></div>
            <div
              class="securityCodeSales-button-right"
              @click="payButton()"
            ></div>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="purchaseShow"
        width="30%"
        :show-close="false"
      >
        <div class="purchaseClass">
          <div class="purchaseClass-gbc">
            <div class="purchaseClass-title">
              {{ typeIndex == 0 ? "购买" : "出售" }}
            </div>
            <img
              src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
              alt=""
              class="purchaseClass-img"
              @click="purchaseShow = !purchaseShow"
            />
            <div class="purchaseClass-merchandise flex">
              <img
                :src="commodityDetails.productImg"
                alt=""
                class="purchaseClass-merchandise-img"
              />
              <div class="purchaseClass-merchandise-text flex">
                <div class="purchaseClass-merchandise-text-title">
                  {{ listSingle.productName }}
                </div>
                <div class="flex items-center flex-between">
                  <div class="purchaseClass-merchandise-text-pic">
                    价格：{{ listSingle.price }}
                  </div>
                  <div>
                    <el-input-number
                      v-model="count"
                      :min="1"
                      :max="
                        (
                          listSingle.totalCount - listSingle.purchasedCount
                        ).toFixed(0) * 1
                      "
                    ></el-input-number>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="purchaseClass-button flex items-center">
            <!-- <div class="purchaseClass-button-title">总价{{ totalprices }}</div> -->
            <div class="purchaseClass-button-button" @click="wantBuy()">
              确认{{ typeIndex == 0 ? "购买" : "出售" }}
            </div>
          </div>
        </div>
      </el-dialog>

      <el-dialog
        title=""
        :visible.sync="show"
        width="30%"
        :show-close="false"
        @close="handleClose()"
      >
        <div class="popupClass">
          <div class="popupClass-title">发布求购</div>
          <img
            src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
            mode=""
            class="popupClass-img"
            @click="close()"
          />
          <div class="popupClass-input flex flex-between">
            <div>
              <el-input placeholder="请输入" v-model="price">
                <div slot="prepend" class="popupClass-input-text">价格</div>
              </el-input>
            </div>
            <div>
              <el-input placeholder="请输入" v-model="counts">
                <div slot="prepend" class="popupClass-input-text">数量</div>
                <div slot="append" class="popupClass-input-text">个</div>
              </el-input>
            </div>
          </div>
          <div class="popupClass-button" @click="releasew()"></div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="reminderShow"
        width="30%"
        :show-close="false"
      >
        <div class="reminderShow">
          <img
            src="https://cdn.bubbleplan.cn/static/marketplace/X.png"
            mode=""
            class="reminderShow-img"
            @click="reminderShow = !reminderShow"
          />
          <div class="reminderShow-title">提示</div>
          <div class="reminderShow-text">
            有用户正在操作该笔订单，请稍后重试
          </div>
          <!-- <div class="reminderShow-text">请稍后重试！</div> -->
          .
          <div
            class="reminderShow-button"
            @click="reminderShow = !reminderShow"
          >
            确定
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import xskcodeinput from "./components/xskcodeinput.vue";
export default {
  components: {
    xskcodeinput,
  },
  data() {
    return {
      reminderShow: false,
      count: 1,
      purchaseShow: false,
      payPassword: "",
      passwordShow: false,
      input: "",
      consignmentSalesShow: false,
      priceShow: false,
      priceRanking: 0,
      priceRankingList: [
        {
          name: "从低到高",
        },
        {
          name: "从高到低",
        },
      ],
      typeIndex: 0,
      typeList: [
        {
          name: "寄售市场",
        },
        {
          name: "求购市场",
        },
      ],
      categoryId: null,
      commodityDetails: {},
      marketList: [],
      page: {
        pageNum: 1,
        pageSize: 20,
      },
      hasNextPage: false,
      userInfo: {},
      sort: 0,
      listSingle: {},
      serviceCharge: null,
      price: "",
      counts: "",
      remainingNum: 0,
      buyorpublish: 0,
      show: false,
      expansionSortShow: false,
      topprice: null,
      nowprice: null,
    };
  },
  computed: {
    totalprices() {
      return (this.count * this.listSingle.price).toFixed(2);
    },
    serviceChargeNuM() {
      if (this.counts && this.price) {
        return (
          this.counts *
          1 *
          (this.price * 1) *
          this.serviceCharge
        ).toFixed(2);
      } else {
        return 0;
      }
    },
    anticipatedIncome() {
      if (this.counts && this.price) {
        return (
          this.counts * 1 * (this.price * 1) -
          this.counts * 1 * (this.price * 1) * this.serviceCharge
        ).toFixed(2);
      } else {
        return 0;
      }
    },
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("user_info"));
    this.categoryId = this.$route.query.categoryId;
    this.typeIndex = 0;
    this.page.pageNum = 1;
    this.getTypeById();
    this.highestpricew();
  },
  methods: {
    handleClose() {
      //   this.price = "";
      //   this.counts = "";
    },
    handleScroll(event) {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.hasNextPage) {
          this.page.pageNum++;
          this.market("bottom");
        }
      }
    },
    expansionSort() {
      this.expansionSortShow = !this.expansionSortShow;
    },
    sortButton(index) {
      this.expansionSortShow = false;
      this.page.pageNum = 1;
      this.sort = index;
      console.log(this.sort);
      this.market();
    },
    close() {
      this.show = false;
    },
    releaseonconsignment() {
      if (this.counts > this.remainingNum) {
        this.$message({
          message: "超出了拥有数量",
          type: "warning",
        });
        return;
      }
      let integer = /^\+?[1-9]\d*$/;

      if (!integer.test(this.counts)) {
        this.$message.error("请输入正确数量");
        return;
      }

      let priceTest = /^\d+(\.\d{1,2})?$/;
      if (!priceTest.test(this.price)) {
        this.$message.error("请输入正确价格");
        return;
      }
      this.passwordShow = true;
      this.payPassword = "";
      this.$refs.xskcodeinputRef.payPassword = "";
    },
    //剩余商品数量
    async remainingQuantity() {
      const { data, code } = await this.$ajax.remainingQuantity({
        categoryId: this.categoryId,
      });
      this.remainingNum = data;
    },
    //获取手续费
    async getRate() {
      const { data, code } = await this.$ajax.getRate();
      this.serviceCharge = data;
    },
    //查询积分手续费
    async getRateNotMoney() {
      const { data, code } = await this.$ajax.getRateNotMoney();
      this.serviceCharge = data;
    },
    //获取价格
    async highestpricew() {
      const params = {
        categoryId: this.categoryId,
        ...this.page,
        type: this.typeIndex == 0 ? 1 : 0,
        orderBy: this.typeIndex == 0 ? 1 : 0,
      };
      const { data, code } = await this.$ajax.market(params);
      if (data.list.length > 0) {
        this.nowprice = data.list[0].price;
      } else {
        this.nowprice = null;
      }
    },
    //获取最高价
    async highestprice() {
      const params = {
        categoryId: this.categoryId,
        ...this.page,
        type: this.typeIndex == 0 ? 1 : 0,
        orderBy: 0,
      };
      const { data, code } = await this.$ajax.market(params);
      if (data.list.length > 0) {
        this.topprice = data.list[0].price;
      } else {
        this.topprice = null;
      }
    },

    release() {
      if (this.typeIndex == 0) {
        if (this.categoryId == 8 || this.categoryId == 29) {
          this.getRate();
        } else {
          this.getRateNotMoney();
        }

        this.remainingQuantity();
        this.highestprice();
        this.consignmentSalesShow = true;
        this.buyorpublish = 0;
      } else {
        this.price = "";
        this.counts = "";
        this.buyorpublish = 1;
        this.show = true;
      }
    },
    securityCodeSalesClose() {
      this.passwordShow = false;
      this.payPassword = "";
      this.$refs.xskcodeinputRef.payPassword = "";
    },
    // 发布求购or寄售
    async releasew() {
      let integer = /^\+?[1-9]\d*$/;
      if (!integer.test(this.counts)) {
        this.$message.error("请输入正确数量");
        return;
      }

      let priceTest = /^\d+(\.\d{1,2})?$/;
      if (!priceTest.test(this.price)) {
        this.$message.error("请输入正确价格");
        return;
      }

      const params = {
        categoryId: this.categoryId,
        count: this.counts,
        price: this.price,
        systemType: "PC",
        type: this.typeIndex == 0 ? 1 : 0,
      };
      if (this.typeIndex == 0) {
        params.payPassword = this.payPassword;
      }
      const { data, code } = await this.$ajax.release(params);
      if (code == 200) {
        if (data) {
          location.href = data;
        }
        this.remainingQuantity();
        this.close();
      }
      this.highestpricew();
      this.consignmentSalesShow = false;
      this.count = "";
      this.price = "";
      this.show = false;
      this.userNum();
      this.page.pageNum = 1;
      this.market();
    },
    //查询积分金币
    async userNum() {
      const { data, code } = await this.$ajax.userNum();
      if (code == 200) {
        this.userProperty(data);
        this.crystalNum();
      }
    },
    //查询晶体or晶体积分
    async crystalNum() {
      const { data, code } = await this.$ajax.crystalNum();
      if (code == 200) {
        this.usercrystalNumData(data);
      }
    },
    //卖给他
    async payButton() {
      console.log(this.payPassword);
      if (this.payPassword.length != 6) {
        this.$message({
          message: "请输入6位数密码",
          type: "warning",
        });
        return;
      }

      if (this.buyorpublish == 0) {
        this.releasew();
        return;
      }

      const params = {
        count: this.count,
        payPassword: this.payPassword,
        id: this.listSingle.id,
      };
      const { data, code, msg } = await this.$ajax.wantBuySell(params);
      if (code == 200) {
        this.page.pageNum = 1;
        this.$refs.xskcodeinputRef.payPassword = "";
        this.payPassword = "";
        this.market();
      } else {
        if (msg == "有用户正在操作该笔订单，请稍后重试！") {
          this.reminderShow = true;
        }
      }
      this.highestpricew();

      this.passwordShow = false;
    },
    async wantBuy() {
      if (this.typeIndex == 0) {
        if (!this.userInfo.accountOpenPayHF) {
          this.$message({
            message: "请先开通钱包",
            type: "warning",
          });
          return;
        }
        const params = {
          count: this.count,
          id: this.listSingle.id,
          systemType: "PC",
        };
        const { data, code, msg } = await this.$ajax.buy(params);
        if (code == 200) {
          this.count = 1;
          if (data) {
            location.href = data;
          }
        } else {
          if (msg == "有用户正在操作该笔订单，请稍后重试！") {
            this.reminderShow = true;
          }
        }
        this.purchaseShow = false;
      } else {
        const datas = await this.$ajax.checkIsSetPayPassword();

        if (datas.data) {
          this.purchaseShow = false;
          this.passwordShow = true;
        } else {
          this.purchaseShow = false;
          this.$message({
            message: "请先设置安全密码",
            type: "warning",
          });
        }
      }
    },

    listButton(item) {
      this.count = 1;
      this.listSingle = item;
      this.consignmentSalesShow = 0;
      this.purchaseShow = true;
    },
    typeButton(index) {
      if (index == this.typeIndex) return;
      if (index == 0) {
        this.sort = 0;
      } else {
        this.sort = 1;
        this.buyorpublish = 1;
      }
      this.expansionSortShow = false;
      this.typeIndex = index;
      this.page.pageNum = 1;
      this.market();
      this.highestpricew();
    },

    // 获取列表
    async market(type) {
      const params = {
        categoryId: this.categoryId,
        ...this.page,
        type: this.typeIndex == 0 ? 1 : 0,
        orderBy:
          this.typeIndex == 0 && this.sort == 0
            ? 1
            : this.typeIndex == 1 && this.sort == 0
            ? 1
            : 0,
      };
      const { data, code } = await this.$ajax.market(params);
      if (type == "bottom") {
        this.marketList.push(...data.list);
      } else {
        this.marketList = data.list;
      }
      this.hasNextPage = data.hasNextPage;
    },
    //查询单个商品详情
    async getTypeById() {
      const { data, code } = await this.$ajax.getTypeById({
        categoryId: this.categoryId,
      });
      if (code == 200) {
        this.commodityDetails = data;
        this.market();
      }
    },
    back() {
      history.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.bazaar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.bazaar {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/marketplace/consignmentsales.png")
    no-repeat;
  background-size: 100% 100%;
  overflow: auto;
  // position: relative;
  display: flex;
  justify-content: center;
  padding-top: 28px;
  &-but {
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 203px;
    height: 413px;
  }
  &-back:hover {
    cursor: pointer;
  }
  &-back {
    position: absolute;
    top: 163px;
    left: 90px;
    width: 72px;
    height: 72px;
  }
  &-content {
    width: 1072px;
    &-type {
      justify-content: center;
      align-items: center;
      &-li:hover {
        cursor: pointer;
      }
      &-li1:hover {
        cursor: pointer;
      }
      &-li {
        width: 421px;
        height: 129px;
        line-height: 89px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10652.png")
          no-repeat;
        background-size: 100% 100%;
        text-align: center;
        color: #36344b;
        font-family: "qiantuhouheiti";
        font-size: 37px;
        margin-right: 10px;
      }
      &-li1 {
        width: 421px;
        height: 129px;
        line-height: 89px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector580.png")
          no-repeat;
        background-size: 100% 100%;
        text-align: center;
        color: #6a6a76;
        font-family: "qiantuhouheiti";
        font-size: 37px;
        margin-right: 10px;
      }
    }
    &-exhibition {
      width: 1025px;
      height: 1162px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10642.png")
        no-repeat;
      background-size: 100% 100%;
      margin-top: -110px;
      position: relative;
      padding: 142px 112px;
      padding-right: 71px;
      &-click {
        position: absolute;
        top: 0;
        &-left:hover {
          cursor: pointer;
        }
        &-left {
          width: 421px;
          height: 69px;
          background: transparent;
          margin-right: 10px;
        }
      }
      &-introduce {
        &-left {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 121px;
          margin-left: 89.1px;

          &-img {
            width: 241px;
            height: 241px;
          }
        }
        &-right {
          flex: 1;
          &-title {
            margin-left: 44px;
            font-family: "qiantuhouheiti";
            color: #ffffff;
            font-size: 28px;
            text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030,
              -2px 2px 0 #341030, 2px 2px 0 #341030;
            /* 模拟描边效果 */
          }
          &-text {
            color: #7b6d6d;
            font-family: "TsangerYuMo";
            font-size: 24px;
            font-weight: bold;
            line-height: 40px;
            margin-top: 19px;
          }
          &-pic {
            color: #af06bd;
            font-size: 32px;
            font-weight: 700;
            margin-left: 7px;
          }
          &-t {
            margin-top: 98px;
            color: #111111;
            width: 395px;
            height: 90px;
            font-size: 19px;
            line-height: 34px;
            &-w {
              width: 395px;
              height: 90px;
              text-overflow: ellipsis;
              word-wrap: break-word;
              overflow-wrap: break-word;
              white-space: normal;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
      &-list {
        margin-top: 133px;
        padding-bottom: 55px;
        .overturn {
          transform: rotateX(180deg);
        }
        &-type {
          width: 100%;
          height: 59px;
          background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector565.png")
            no-repeat;
          background-size: 100% 100%;
          padding-left: 251px;
          align-items: center;
          color: #ffffff;
          font-size: 18px;
          font-weight: Bold;
          position: relative;
          &-left:hover {
            cursor: pointer;
          }
          &-left {
            align-items: center;
            &-img {
              width: 16.5px;
              height: 8px;
              margin-left: 10px;
            }
          }
          &-right {
            margin-left: 176.5px;
          }
          &-show {
            width: 147px;
            height: 135px;
            position: absolute;
            bottom: -125px;
            left: 202px;
            font-size: 19px;
            line-height: 50px;
            text-align: center;
            div:hover {
              cursor: pointer;
            }

            .capreolusPygargus {
              width: 129px;
              height: 50px;
              flex-shrink: 0;
              border: 1px solid #bd9d8b;
              background: #fff;
              color: #574352;
              font-size: 18px;
              font-weight: 400;
            }
          }
        }
        &-ul::-webkit-scrollbar {
          width: 0px;
          height: 0px;
        }
        &-ul {
          width: 100%;
          height: 420px;
          overflow: auto;
          &-li:nth-child(2n) {
            width: 100%;
            height: 59px;
            background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector565.png")
              no-repeat;
            background-size: 100% 100%;
            color: #ffffff;
          }
          &-li {
            width: 100%;
            height: 59px;
            align-items: center;
            text-align: center;
            color: #8b8579;
            font-weight: Bold;
            font-size: 18px;
            &-img {
              width: 54px;
              height: 53px;
              display: inline-block;
            }
            &-but:hover {
              cursor: pointer;
            }
            &-but {
              width: 93px;
              height: 38px;
              background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector12565.png")
                no-repeat;
              background-size: 100% 100%;
              line-height: 38px;
              font-size: 20px;
              color: #ffffff;
            }
          }
        }
        &-button:hover {
          cursor: pointer;
        }
        &-button1:hover {
          cursor: pointer;
        }
        &-button {
          margin: auto;
          margin-top: 20px;
          width: 307px;
          height: 81px;
          background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector123607.png")
            no-repeat;
          background-size: 100% 100%;
          color: #3e2839;
          text-align: center;
          font-family: "qiantuhouheiti";
          font-size: 35px;
          line-height: 71px;
        }
        &-button1 {
          margin: auto;
          margin-top: 45px;
          width: 385px;
          height: 67px;
          background: url("https://cdn.bubbleplan.cn/static/marketplace/Rectangle34626012x.png")
            no-repeat;
          background-size: 100% 100%;
          color: #0e0e18;
          text-align: center;
          font-size: 24px;
          font-weight: 700;
          line-height: 67px;
        }
      }
    }

    :deep(.el-dialog__header) {
      padding: 0;
    }
    :deep(.el-dialog__body) {
      padding: 0;
    }
    :deep(.el-dialog) {
      //   width: 676px !important;
      background: transparent !important;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .consignmentSales {
      width: 524px;
      // height: 909px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Rectangle34626259.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 43px 19px;
      &-title {
        width: 132px;
        height: 68px;
        color: #000000;
        text-align: center;
        font-size: 24px;
        line-height: 68px;
      }
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        position: absolute;
        right: -18px;
        top: -2px;
        width: 48px;
        height: 48px;
      }
      &-commodity {
        width: 100%;
        border-radius: 14px;
        background: #f3ece4;
        padding: 21px;
        &-img {
          width: 82.61px;
          height: 82.61px;
          border-radius: 12px;
          margin-right: 15.27px;
        }
        &-title {
          color: #4d3283;
          font-size: 19px;
          font-weight: Bold;
          margin-top: 6px;
        }
        &-num {
          color: #7f7199;
          font-size: 18px;
          margin-bottom: 6px;
        }
      }
      &-retail {
        margin-top: 17px;
        width: 100%;
        border-radius: 14px;
        background: #f3ece4;
        padding: 21px 22px;
        &-title {
          color: #4d3283;
          font-size: 20px;
          font-weight: Bold;
        }
        &-input {
          margin-top: 24.29px;
          :deep(.el-input__inner) {
            height: 62.48px;
            border-radius: 10.41px;
            border: 0px solid #b6bbbe;
            background: #dbc9bf;
            font-size: 20px;
          }
          :deep(.el-input__inner)::placeholder {
            font-size: 20px;
            color: #574352;
          }
        }
        &-serviceCharge {
          margin-top: 20.8px;
          color: #7f7199;
          font-size: 18px;
        }
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button {
        margin: auto;
        margin-top: 30px;
        width: 329px;
        height: 77px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector123607.png")
          no-repeat;
        background-size: 100% 100%;
        color: #0e0e18;
        text-align: center;
        font-size: 30px;
        font-family: "qiantuhouheiti";
        line-height: 67px;
      }
    }
    .securityCodeSales {
      width: 469px;
      height: 261px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/488-271.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 20px 55px;
      position: relative;
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        position: absolute;
        top: 2px;
        right: -11px;
        width: 49px;
        height: 48px;
      }
      &-title {
        color: #574352;
        font-family: "TsangerYuMo";
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 56px;
      }
      &-button {
        margin-top: 50px;
        &-left:hover {
          cursor: pointer;
        }
        &-right:hover {
          cursor: pointer;
        }
        &-left {
          width: 135px;
          height: 54px;
          background: url("https://cdn.bubbleplan.cn/static/marketplace/Gro123up10207.png")
            no-repeat;
          background-size: 100% 100%;
          line-height: 67px;
          text-align: center;
          color: #2d2d2d;
          font-size: 24px;
          font-weight: 700;
        }
        &-right {
          width: 135px;
          height: 54px;
          background: url("https://cdn.bubbleplan.cn/static/marketplace/Gr13oup10206.png")
            no-repeat;
          background-size: 100% 100%;
          line-height: 54px;
          text-align: center;
          color: #2d2d2d;
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
    .purchaseClass {
      width: 488px;
      height: 300px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/488-271.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 20px 60px;
      &-title {
        color: #574352;
        text-align: center;
        font-family: "TsangerYuMo";
        font-weight: bold;
        font-size: 24px;
      }
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        position: absolute;
        top: 2px;
        right: -11px;
        width: 49px;
        height: 48px;
      }
      &-merchandise {
        width: 100%;
        // height: 187px;
        margin-top: 50px;
        &-img {
          width: 120px;
          height: 120px;
          margin-right: 20px;
        }
        &-text {
          flex: 1;
          flex-direction: column;
          justify-content: space-between;
          &-title {
            color: #574352;
            font-size: 20px;
          }
          &-pic {
            color: #574352;
            font-size: 18px;
          }
          :deep(.el-input-number__decrease) {
            width: 27px;
            height: 27px;
            background: url("https://cdn.bubbleplan.cn/static/marketplace/Group9635.png")
              no-repeat;
            background-size: 100% 100%;
            border: 0 !important;
          }
          :deep(.el-icon-minus) {
            display: none;
          }
          :deep(.el-input__inner) {
            height: 27px;
            line-height: 27px;
            border: none;
          }
          :deep(.el-input-number__increase) {
            width: 27px;
            height: 27px;
            background: url("https://cdn.bubbleplan.cn/static/marketplace/Group9636.png")
              no-repeat;
            background-size: 100% 100%;
            border: 0 !important;
          }
          :deep(.el-icon-plus) {
            display: none;
          }
          :deep(.el-input__inner) {
            font-size: 21px;
            background: transparent !important;
          }
        }
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button {
        padding: 27px 45px;
        margin-left: 3px;
        bottom: 0;
        border-radius: 0 0 25px 25px;
        justify-content: center;
        margin-top: 10px;
        // justify-content: flex-end;
        // &-title {
        //   color: #9a13a5;
        //   font-size: 23px;
        //   font-weight: 700;
        //   margin-right: 24px;
        // }
        &-button {
          width: 143px;
          height: 54px;
          line-height: 54px;
          text-align: center;
          background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector33606.png")
            no-repeat;
          background-size: 100% 100%;
          font-family: "TsangerYuMo";
          color: #f3e9d0;
          font-size: 22px;
          font-weight: 700;
        }
      }
    }
    .popupClass {
      width: 495px;
      height: 275px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/488-271.png")
        no-repeat;
      background-size: 100% 100%;
      padding: 21px 39px 0 49px;
      position: relative;
      &-title {
        color: #574352;
        font-family: "TsangerYuMo";
        text-align: center;
        font-size: 25px;
        font-weight: bold;
      }
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        width: 49px;
        height: 48px;
        position: absolute;
        right: -12px;
        top: -2px;
      }

      &-input {
        margin-top: 62px;
        // padding: 36px 34px;
        :deep(.el-input-group) {
          width: 193px;
          height: 58px;
          background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector560.png")
            no-repeat;
          background-size: 100% 100%;
        }
        :deep(.el-input__inner) {
          height: 56px;
          background: transparent;
          border: none;
          font-size: 22px;
          font-weight: 700;
        }
        :deep(.el-input__inner)::placeholder {
          color: #e6d9d9;
          font-size: 18px;
        }
        :deep(.el-input-group__prepend) {
          background: transparent;
          border: none;
        }
        :deep(.el-input-group__append) {
          background: transparent;
          border: none;
          padding: 0;
          padding-right: 10px;
        }
        &-text {
          color: #ffffff;
          font-size: 20px;
          font-weight: Bold;
        }
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button {
        margin: auto;
        margin-top: 63px;
        width: 159px;
        height: 55px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Gro123up10206.png")
          no-repeat;
        background-size: 100% 100%;
        color: rgb(14, 14, 24);
        text-align: center;
        font-size: 27px;
        font-weight: 700;
        line-height: 55px;
      }
    }
    .reminderShow {
      width: 497px;
      height: 276px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/488-271.png")
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      padding: 21px 27px 0 65px;
      &-img:hover {
        cursor: pointer;
      }
      &-img {
        width: 49px;
        height: 48px;
        position: absolute;
        right: -12px;
        top: -2px;
      }
      &-title {
        color: #574352;
        font-family: "TsangerYuMo";
        text-align: center;
        font-size: 25px;
        font-weight: bold;
      }
      &-text {
        margin-top: 72px;
        color: #574352;
        text-align: center;
        font-size: 22px;
        font-weight: Bold;
      }
      &-button:hover {
        cursor: pointer;
      }
      &-button {
        margin: auto;
        margin-top: 66px;
        width: 148px;
        height: 54px;
        background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector33606.png")
          no-repeat;
        background-size: 100% 100%;
        line-height: 54px;
        text-align: center;
        font-family: "TsangerYuMo";
        font-size: 22px;
        color: #f3e9d0;
        font-weight: bold;
      }
    }
  }
}
</style>